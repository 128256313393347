<template>
  <div>
    <div class="apply_t">
      <h5>
        申请试用
        <img src="../assets/images/close.png" @click="onclose()" />
      </h5>
      <p>让营销变得更简单，让增长自然而然</p>
    </div>

    <div class="apply">
      <el-form :model="formInline" label-position="right" label-width="1.5rem">
        <el-form-item label="姓名：">
          <el-input
            v-model="formInline.user"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号："
          prop="phone"
          :rules="[
            { required: true, message: '手机号不能为空' },
            { type: 'number', message: '手机号必须为数字值' },
          ]"
        >
          <el-input
            v-model.number="formInline.phone"
            autocomplete="off"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input
            v-model="formInline.mali"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位：">
          <el-input
            v-model="formInline.position"
            placeholder="请输入职位"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称：">
          <el-input
            v-model="formInline.company"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="submit">
        <button>提交</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props:['childEvent'],
  data() {
    return {
      formInline: {
        user: "",
        phone: "",
        mali: "",
        position: "",
        company: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    onclose() {
        this.childEvent(false)
    },
  },
};
</script>

<style scoped lang="less">
.apply_t {
  color: #fff;
  text-align: center;
  h5 {
    font-size: 0.36rem;
    padding: 2.36rem 0 0;
    line-height: 0.6rem;
    position: relative;
    img {
      position: absolute;
      right: 2.5rem;
      width: 0.5rem;
    }
  }
  p {
    font-size: 0.24rem;
  }
}
.apply {
  width: 14.16rem;
  height: 3.92rem;
  background-color: #fff;
  margin: 0.48rem auto;
  border-radius: 0.1rem;
  box-sizing: border-box;
  padding: 0.66rem;
}
.submit {
  text-align: center;
  button {
    width: 4.73rem;
    height: 0.44rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    border-radius: 0.05rem;
  }
}

.el-form {
  display: flex;
  flex-wrap: wrap;
}
</style>
